.auto-complete-input {
    margin: 0 !important;
}

.merge-collection-paper {
    padding: 20px 20px !important;
}

.merge-predefine-title {
    font-weight: bold !important;
    font-size: 14px !important;
    color: #16365F !important;
}

.merge-predefine-menu {
    padding-left: 24px !important;
    font-size: 13px !important;
    color: #333 !important;
}